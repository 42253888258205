import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import logo from '../../img/tbsx3-4.png';
import { FormattedMessage } from 'react-intl';

export default class PageNotFound extends Component {
    render() {
        return (
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
            >
                <img src={logo} style={{height: '100px'}} alt="TBSx3 logo"/>
                <Typography className="title">
                    <FormattedMessage id='PAGE_NOT_FOUND' defaultMessage={'Page Not Found!'} />
                </Typography>
                <Typography className="subTitle">
                    <FormattedMessage id='PAGE_NOT_FOUND_DESCRIPTION' defaultMessage={'This Page does not exist!'} />
                </Typography>
            </Grid>

        );
    }
}
