const initialState = {
   companyInfo: null,
   isLoading: true,
   error: undefined
}

const actionsMap = {
   GET_COMPANY(state, action) {
      const payload = action.payload ? action.payload : null
      if (payload.status === 200) {
         return {
            ...state,
            isLoading: false,
            companyInfo: payload.data.data,
         }
      } else {
         return {
            ...state,
            isLoading: false,
            error: payload.response.data,
         }
      }
   },
}

export default (state = initialState, action) => {
   const reduceFn = actionsMap[action.type]
   if (!reduceFn) {
      return state
   }
   return reduceFn(state, action)
}
