import React, { Component } from "react";
import {Route} from 'react-router-dom';

export default class OnePager extends Component {
  render() {
    return (
      <Route render={() => (window.location = "https://aglive.com/mackas-australian-beef-low-and-slow/")} />
    )
  }
}
/*
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { COLOR } from "../../config/theme";

// Image import
import iconTick from "../../img/iconTick.svg";
import protectedby from "../../img/group.svg";
import meat from "../../img/meat.svg";
import acceptImg from "../../img/accept.png";
import releaseImg from "../../img/release.png";
import aglive3Layer from "../../img/aglive-3-layer.svg";
import qrcode from "../../img/qr_code_2-black.svg";
import rfid from "../../img/contactless-outlined.svg";
import rewards from "../../img/rewards.svg";
import phonePreview from "../../img/phone-preview.svg";
import consumerApp from "../../img/consumer-app.svg";
import android from "../../img/android-black.svg";
import apple from "../../img/appleicon.svg";

export default class OnePager extends Component {
  render() {
    return (
      <Grid
        container
        direction="column"
        style={{ backgroundColor: "#F7F7F7" }}
        alignItems="center"
      >
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          style={{ marginTop: 40 }}
        >
          <Typography variant="h3" style={{ fontSize: "19px" }}>
            Use our{" "}
            <span style={{ color: COLOR.GREEN }}>free Aglive Trust app</span>
          </Typography>
          <Typography style={{ marginTop: 16 }}>
            Protect your loved ones
          </Typography>
          <Typography>from fake products</Typography>
          <Typography
            calssName="openSans"
            style={{ marginTop: 16, fontWeight: 700 }}
          >
            Download now
          </Typography>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="space-evenly"
          style={{ marginTop: 16 }}
        >
          <Button
            style={{
              width: 128,
              height: 40,
              borderRadius: 20,
              backgroundColor: COLOR.WHITE,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
            }}
            variant="outlined"
            href="https://play.google.com/store/apps/details?id=com.mobilev2.customer"
          >
            <img
              src={android}
              alt=""
              style={{ margin: 8, width: 21, height: 24 }}
            />
            Android
          </Button>
          <Button
            style={{
              width: 128,
              height: 40,
              borderRadius: 20,
              backgroundColor: COLOR.WHITE,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
            }}
            variant="outlined"
            href="https://apps.apple.com/au/app/aglivetrust/id1530764707"
          >
            <img src={apple} alt="" style={{ margin: 8 }} />
            IOS
          </Button>
        </Grid>
        <div
          style={{
            height: 1,
            width: "80vw",
            padding: "0px 18px 0px 18px",
            backgroundColor: "#DDDDDD",
            marginTop: 23.5,
            marginBottom: 16.5,
          }}
        />
        <Grid
          container
          item
          alignItems="center"
          direction="column"
          style={{ width: 326 }}
        >
          <Typography className="body-text" style={{ marginTop: 16 }}>
            Make sure you get what you paid for
          </Typography>
          <Grid
            container
            item
            justify="center"
            alignItems="center"
            direction="column"
            style={{ backgroundColor: COLOR.WHITE, marginTop: 72 }}
          >
            <img
              src={iconTick}
              alt=""
              style={{ position: "relative", bottom: 47 }}
            />
            <Grid
              item
              container
              style={{ width: 296, height: 80 }}
              justify="center"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Oswald",
                  fontSize: 28,
                  fontWeight: 400,
                  marginBottom: 8,
                }}
              >
                No Threat Detected
              </Typography>
            </Grid>
            <img src={meat} alt="" />
            <Typography
              className="openSans"
              style={{
                color: COLOR.WHITE,
                fontWeight: 700,
                fontSize: 58,
                position: "absolute",
                transform: "rotate(-26deg)",
                marginTop: 58,
              }}
            >
              SAMPLE
            </Typography>
            <Typography
              className="openSans"
              style={{ fontSize: 14, marginTop: 8 }}
            >
              Product
            </Typography>
            <Typography
              className="openSans"
              style={{ fontSize: 18, fontWeight: 700 }}
            >
              Sample Beef
            </Typography>
            <Typography className="openSans" style={{ fontSize: 14 }}>
              Australia
            </Typography>
            <img
              src={protectedby}
              alt=""
              style={{ marginTop: 30, marginBottom: 14 }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="column"
          style={{
            margin: "16px 16px 0 16px",
            width: 326,
            background: COLOR.WHITE,
          }}
          alignItems="center"
        >
          <Grid item direction="column">
            <Typography className="openSans" style={{ marginTop: 24 }}>
              Product
            </Typography>
            <Typography className="openSans" style={{ fontWeight: "bold" }}>
              Sample Beef
            </Typography>
            <Typography className="openSans" style={{ marginBottom: 8 }}>
              Australia
            </Typography>
            <Divider />
            <Typography
              className="openSans"
              style={{ fontWeight: "bold", marginTop: 8 }}
            >
              About Product
            </Typography>
            <Typography className="openSans">
              Our beef is produced by a renowned <br />
              family-run business in Australia. Top quality <br />
              grass-fed beed raised in the Riverina region <br />
              of New South Wales.
            </Typography>
            <Typography
              className="openSans"
              style={{ fontWeight: "bold", marginTop: 16 }}
            >
              Beef Type
            </Typography>
            <Typography className="openSans">
              Black Angus, 150-day fed
            </Typography>
            <Typography
              className="openSans"
              style={{ fontWeight: "bold", marginTop: 16 }}
            >
              Pack Weight
            </Typography>
            <Typography className="openSans" style={{ marginBottom: 24 }}>
              500 gms
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          style={{
            margin: "16px 16px 0 16px",
            width: 326,
            backgroundColor: COLOR.WHITE,
          }}
        >
          <Grid
            container
            item
            justify="space-evenly"
            alignItems="center"
            style={{ height: 90 }}
          >
            <Grid item>
              <Typography className="body-text" style={{ maxWidth: 91 }}>
                27 Oct 2019
              </Typography>
              <Typography
                className="caption"
                style={{ color: "#B5B5B5", textAlign: "right" }}
              >
                01:10
              </Typography>
            </Grid>
            <Grid item>
              <div
                style={{
                  width: 3,
                  height: 31,
                  position: "relative",
                  left: 6,
                  backgroundColor: COLOR.WHITE,
                }}
              />
              <img
                style={{ width: 15, height: 28, position: "relative" }}
                src={releaseImg}
                alt={"release"}
              />
              <div
                style={{
                  width: 3,
                  height: 32,
                  position: "relative",
                  top: -4,
                  left: 6,
                  backgroundColor: COLOR.GREEN,
                }}
              />
            </Grid>
            <Grid item>
              <Typography
                className="body-text text-hidden"
                style={{ maxHeight: 46, width: 117 }}
              >
                Sample Beef
              </Typography>
              <Typography
                className="caption"
                noWrap
                style={{ color: "#B5B5B5" }}
              >
                NSW, Australia
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justify="space-evenly"
            alignItems="center"
            style={{ height: 90 }}
          >
            <Grid item>
              <Typography className="body-text" style={{ maxWidth: 91 }}>
                28 Oct 2019
              </Typography>
              <Typography
                className="caption"
                style={{ color: "#B5B5B5", textAlign: "right" }}
              >
                08:10
              </Typography>
            </Grid>
            <Grid item>
              <div
                style={{
                  width: 3,
                  height: 31,
                  position: "relative",
                  left: 6,
                  backgroundColor: COLOR.GREEN,
                }}
              />
              <img
                style={{ width: 15, height: 28, position: "relative" }}
                src={acceptImg}
                alt={"release"}
              />
              <div
                style={{
                  width: 3,
                  height: 32,
                  position: "relative",
                  top: -4,
                  left: 6,
                  backgroundColor: COLOR.GREEN,
                }}
              />
            </Grid>
            <Grid item>
              <Typography
                className="body-text text-hidden"
                style={{ maxHeight: 46, width: 117 }}
              >
                Truck Company
              </Typography>
              <Typography
                className="caption"
                noWrap
                style={{ color: "#B5B5B5" }}
              >
                NSW, Australia
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justify="space-evenly"
            alignItems="center"
            style={{ height: 90 }}
          >
            <Grid item style={{ maxWidth: 91 }}>
              <Typography className="body-text">29 Oct 2019</Typography>
              <Typography
                className="caption"
                style={{ color: "#B5B5B5", textAlign: "right" }}
              >
                03:10
              </Typography>
            </Grid>
            <Grid item>
              <div
                style={{
                  width: 3,
                  height: 31,
                  position: "relative",
                  left: 6,
                  backgroundColor: COLOR.GREEN,
                }}
              />
              <img
                style={{ width: 15, height: 28, position: "relative" }}
                src={acceptImg}
                alt={"release"}
              />
              <div
                style={{
                  width: 3,
                  height: 32,
                  position: "relative",
                  top: -4,
                  left: 6,
                  backgroundColor: COLOR.WHITE,
                }}
              />
            </Grid>
            <Grid item>
              <Typography
                className="body-text text-hidden"
                style={{ maxHeight: 46, width: 117 }}
              >
                Air Cargo
              </Typography>
              <Typography
                className="caption"
                noWrap
                style={{ color: "#B5B5B5" }}
              >
                NSW, Australia
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 24 }} alignItems="center">
          <div
            style={{
              height: 13,
              width: 52,
              position: "relative",
              backgroundColor: COLOR.GREEN,
              right: 16,
            }}
          />
          <Typography
            className="title"
            style={{ color: COLOR.GRAY_CAPTION, fontWeight: "bold" }}
          >
            Three Layers of Protection
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 16 }}>
          <Grid container item xs={6} justify="center">
            <Typography className="caption">
              Every protected item, can,
              <br /> packet, or bottle has a <br />
              unique ID code on it.
              <br />
              <br />
              Our platform checks if the <br />
              code is a genuine encrypted <br />
              code. We check the <br />
              blockchain record to <br />
              determine if any supply <br />
              chain issues have been <br />
              detected e.g. Should this <br />
              item be for sale in that <br />
              location?
              <br />
              <br />
              Codes can only be used <br />
              once. This stops the usual <br />
              counterfeiting tactic of <br />
              making multiple copies of a <br />
              single genuine item.
            </Typography>
          </Grid>
          <Grid container item xs={6} justify="center">
            <img src={aglive3Layer} alt="" />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginTop: 24 }}
          alignItems="center"
          justify="flex-end"
        >
          <Typography
            className="title"
            style={{ color: COLOR.GRAY_CAPTION, marginRight: 8 }}
          >
            A Single Scan for Protection
          </Typography>
          <div
            style={{
              height: 13,
              width: 52,
              backgroundColor: COLOR.GREEN,
              right: 16,
            }}
          />
        </Grid>
        <Grid container style={{ marginTop: 16 }} direction="column">
          <Grid container item justify="center">
            <Typography className="caption">
              Just a scan via our app to bring you an unique protection. <br />
              Our app supports:
            </Typography>
          </Grid>
          <Grid container justify="center" style={{ marginTop: 10 }}>
            <Grid
              item
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                width: 80,
                height: 72,
                backgroundColor: COLOR.WHITE,
                marginRight: 4,
              }}
            >
              <img src={qrcode} alt="" style={{ height: 28, width: 28 }} />
              <Typography className="caption" style={{ fontWeight: 600 }}>
                QR Code
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                width: 80,
                height: 72,
                backgroundColor: COLOR.WHITE,
                marginLeft: 4,
              }}
            >
              <img src={rfid} alt="" style={{ height: 28, width: 28 }} />
              <Typography className="caption" style={{ fontWeight: 600 }}>
                RFID
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 24 }} alignItems="center">
          <div
            style={{
              height: 13,
              width: 52,
              position: "relative",
              backgroundColor: COLOR.GREEN,
              right: 16,
            }}
          />
          <Typography
            className="title"
            style={{ color: COLOR.GRAY_CAPTION, fontWeight: "bold" }}
          >
            Register for Exciting Rewards
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: 16 }} direction="column">
          <Grid container item justify="center">
            <Typography className="caption">
              Create an account with Aglive to win rewards by <br />
              participating in fun surveys and other exciting offers
            </Typography>
          </Grid>
          <Grid container item justify="center" style={{ marginTop: 32 }}>
            <img src={rewards} alt="" />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginTop: 24 }}
          alignItems="center"
          justify="flex-end"
        >
          <Typography
            className="title"
            style={{
              color: COLOR.GRAY_CAPTION,
              marginRight: 8,
              fontWeight: "bold",
            }}
          >
            Multiple Languages
          </Typography>
          <div
            style={{
              height: 13,
              width: 52,
              backgroundColor: COLOR.GREEN,
              right: 16,
            }}
          />
        </Grid>
        <Grid container style={{ marginTop: 19 }}>
          <Grid container item xs={6} justify="center">
            <img src={phonePreview} alt="" />
          </Grid>
          <Grid
            container
            item
            xs={6}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <img src={consumerApp} alt="" />
            <Typography className="caption" style={{ marginTop: 16 }}>
              Now in two languages:
            </Typography>
            <Typography className="caption" style={{ marginTop: 8 }}>
              English, Chinese, and <br />
              more to come...
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ height: 176, backgroundColor: COLOR.BLACK }}
          justify="center"
          alignItems="center"
          direction="column"
        >
          <Typography
            className="openSans"
            style={{ fontSize: 14, color: COLOR.WHITE }}
          >
            Download the{" "}
            <span style={{ fontWeight: 700, color: COLOR.GREEN }}>
              free Aglive Trust app
            </span>{" "}
            now
          </Typography>
          <Grid
            container
            item
            direction="row"
            justify="space-evenly"
            alignItems="center"
            style={{ marginTop: 16 }}
          >
            <Button
              style={{
                width: 128,
                height: 40,
                borderRadius: 20,
                backgroundColor: COLOR.WHITE,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
              variant="outlined"
              href="https://play.google.com/store/apps/details?id=com.mobilev2.customer"
            >
              <img
                src={android}
                alt=""
                style={{ margin: 8, width: 21, height: 24 }}
              />
              Android
            </Button>
            <Button
              style={{
                width: 128,
                height: 40,
                borderRadius: 20,
                backgroundColor: COLOR.WHITE,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
              variant="outlined"
              href="https://apps.apple.com/au/app/aglivetrust/id1530764707"
            >
              <img src={apple} alt="" style={{ margin: 8 }} />
              IOS
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}*/
