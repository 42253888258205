const constant = {
   MEDIATYPE: {
      VIDEO: 'VIDEO',
      IMG: 'IMG'
   },
   DEFAULTVIDEO: 'https://tbsx3-dev.s3-ap-southeast-1.amazonaws.com/files/Video+with+subtitles.mp4',
   TEXT: {
      //A
      ACTIVITY_NAME: 'ACTIVITY_NAME',
      ACTIVITY_INFO: 'ACTIVITY_INFO',
      //C
      CODE_BY: 'CODE_BY',
      CREATED_ON: 'CREATED_ON',
      COMPANY_INFO: 'COMPANY_INFO',
      //D
      DELIVERY_HISTORY: 'DELIVERY_HISTORY',
      //E
      ERR_LOAD: 'ERR_LOAD',
      //L
      LOAD: 'LOAD',
      //P
      PRODUCT_DESCRIPTION: 'PRODUCT_DESCRIPTION',
      PRODUCT_INFO: 'PRODUCT_INFO',
      //T
      TRANSFER: 'TRANSFER',
      //W
      WEBSITE: 'WEBSITE',

      DELIVERED_BY: 'Delivered by',
      RECEIVED_BY: 'Received by',
      SOLD_BY: 'Sold by',
      
      ERR_UNEXPECTED: 'Unexpected Error',
      
      
      TRANSFERED_BY: 'Transfered By',
      LOADED_BY: 'Loaded By',
   },
   ACTIVITY: {
      TRANSFER: 'TRANSFER',
      TRANSFERED_BY: 'TRANSFERED_BY',
      LOADED_BY: 'LOADED_BY',
      LOAD: 'LOAD'
   }
}

module.exports = constant;