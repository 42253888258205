import { mapLanguage } from '../utils/theme'

const defaultLocal = navigator.language.split('-')[0]

const initialState = {
   locale: defaultLocal,
   messageIntl: mapLanguage(defaultLocal)
}

const actionsMap = {
   CHANGE_LOCALE(state, action) {
      const payload = action.payload ? action.payload : null
      if (payload) {
         return {
            locale: payload,
            messageIntl: mapLanguage(payload)
         }
      }
      return state
   }
}

export default (state = initialState, action) => {
   const reduceFn = actionsMap[action.type]
   if (!reduceFn) return state
   return reduceFn(state, action)
}
