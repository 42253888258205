import axios from 'axios'
import API from '../config/api'
import {
  FIND_PRODUCT_ACTION,
} from './'

//Get the one product
export function productFindOne(productId) {
  const url = `${API.GET.productFindOne}/${productId}`
  const config = {
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  const request = axios.request(config)
  return {
    type: FIND_PRODUCT_ACTION,
    payload: request,
  }
}
