import axios from 'axios';
import API from '../config/api';

import {
  SCAN_ITEM,
} from './';

export function scanItem(token) {
  const url = `${API.POST.scanItem}${token}`;
  const config = {
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  };
  const request = axios.request(config);
  return {
    type: SCAN_ITEM,
    payload: request,
  };
}