import axios from 'axios'
import API from '../config/api'
import { GET_COMPANY } from './'

export function getCompany(id) {
   const url = `${API.GET.getCompany}${id}`;
   const config = {
      url,
      method: 'GET',
      headers: {
         'Content-Type': 'application/x-www-form-urlencoded',
      }
   };
   const request = axios.request(config);
   return {
      type: GET_COMPANY,
      payload: request,
   };
}