import React, { Component } from 'react'
// import { PropTypes } from 'prop-types'

import YouTube from 'react-youtube'
import getYouTubeId from 'get-youtube-id'
//material UI import
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { FormattedMessage } from 'react-intl'

//react-redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// //product api call
import { findOneItemMongo } from '../../actions/index'

// //component import
// import NoPermission from '../../../components/NoPermision'

// import itemIcon from '../../../img/item-icon.png'
import { httpHandler } from '../../utils/httpHandler'
// import ErrorHandler from '../../../components/ErrorHandler'
const httpHandlerFunc = httpHandler

class ViewItemReport extends Component {
  // static contextTypes = {
  //   router: PropTypes.object,
  // }

  // static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      oneItemInfo: null,
      error: null,
      width: 0,
      height: 0,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.props.findOneItemMongo(this.props.match.params.id).then(res => {
      const result = httpHandlerFunc(res)
      if (result.mes === 'SUCCESS') {
        console.log('result', result.data)
        const oneItemInfo = result.data
        this.setState({
          oneItemInfo,
        })
      }
      if (result.mes === 'FAILURE') {
        this.setState({ error: result, loading: false })
      }
    }).catch((err) =>
      this.setState({ error: err })
    )
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  productAttributes = (object, key) => {
    return (
      <div id={key}>
        <row>{object.key+': '}</row>
        <row>{object.value}</row>
      </div>
    )
  }
  
  render() {
    if(this.state.oneItemInfo){
      let product = this.state.oneItemInfo.product
      //library to get youtube id
      let id = getYouTubeId(product.videoCover)
      //videoID = videoID.substring(videoID.indexOf("=") + 1);
      //youtube video option
      console.log('width', this.state.width)
      const opts = {
        maxHeight: '390',
        maxWidth: '640',
        playerVars: {
          autoplay: 0,
        },
      }
      return (
        <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        >
          <div style={{ textAlign: 'left' }}>
            <YouTube videoId={id} opts={opts} onReady={this._onReady} />
          </div>
          <div>
            {this.state.oneItemInfo.name}
          </div>
          {this.state.oneItemInfo.URL?
          <div>
            {'Product Websit: '+this.state.oneItemInfo.URL}
          </div>
          :
          null}
          {product.attributes.map((object, key) => this.productAttributes(object, key))}
        </Grid>
      )
    } else if (this.state.error) {
      return (
      <div>
        {'failed to load because of the error: '+ this.state.error }
      </div>
      )
    }
    return (
      <CircularProgress />
    )
  }
}

const mapStateToProps = state => {
  return {
    itemInfo: state.oneItemInfo,
  }
}

export default connect(mapStateToProps, dispatch => bindActionCreators({ findOneItemMongo }, dispatch))(ViewItemReport)