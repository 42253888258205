import zh_CN from '../constant/intl/zh'
import en_GB from '../constant/intl/en'

function mapLanguage(locale) {
   let message = en_GB
   switch (locale) {
      case 'en':
         message = en_GB
         break;
      case 'zh':
         message = zh_CN
         break;
      default:
         break;
   }
   return message
}

export { mapLanguage }