
const initialState = {
  itemInfo: {
    name: '',
    status: '',
    description: '',
  },
  allItemsRecords: [],
  record: 5,
  total: 1,
  error: null,
  itemCSV: null,
}

const actionsMap = {
  GET_ONE_ITEM(state, action) {
    const payload = action.payload ? action.payload : null
    if (payload.status === 200) {
      return {
        itemInfo: payload.data.data,
      }
    }
    return state
  }
}

export default (state = initialState, action) => {
  const reduceFn = actionsMap[action.type]
  if (!reduceFn) return state
  return reduceFn(state, action)
}
