import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { COLOR } from '../../config/theme'
import SvgIcon from '@material-ui/core/SvgIcon';

export default class Breadcrumbs extends Component {

   render() {
      return (
         <Grid
            container
            direction="row"
         >
            <Grid
               item
               style={{
                  backgroundColor: COLOR.GRAY_BUTTON,
                  borderRadius: 25,
                  paddingLeft: 20,
                  paddingRight: 20, 
                  paddingTop: 7,
                  paddingBottom: 7
               }}
            >
               <Link
                  href={this.props.href}
                  underline='none'
               >
               <Typography className='caption' style={{ display: 'inline' }}>
                  {this.props.name}
               </Typography>
               </Link>
               <SvgIcon style={{position:'absolute',fontSize: 21}} width="24" height="24" viewBox="0 0 24 24" color='disabled'>
                  <path fill="none" d="M0 0h24v24H0V0z" />
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
               </SvgIcon>
               <Typography className='caption' style={{ display: 'inline', marginLeft: 22, color: COLOR.GREEN_BUTTON }}>
                  {this.props.curName}
               </Typography>
            </Grid>
         </Grid>
      );
   }
}

Breadcrumbs.propTypes = {
   style: PropTypes.object,
   href: PropTypes.string.isRequired,
   name: PropTypes.object.isRequired,
   curName: PropTypes.object.isRequired,
}