const success = 'SUCCESS'
const failure = 'FAILURE'
const noResponse = 'No Response'

function httpHandler(res) {
  const result = {};
  const payload = res.payload ? res.payload : null;
  if (payload.status === 200) {
    result.mes = success
    result.data = payload.data
    result.total = payload.data.total
  } else {
    result.mes = failure
    result.status = payload.response ? payload.response.status : noResponse
    result.failedText = payload.response ? payload.response.data.message : noResponse
  }
  return result
}

export { httpHandler }
