import React from 'react'
import createBrowserHistory from 'history/createBrowserHistory'
import { Router, Route, Switch } from 'react-router-dom'
import Product from './containers/Product'
import Item from './containers/Item'
import Scan from './containers/Scan'
import Company from './containers/Company'
import Activity from './containers/Activity'
import PageNotFound from './components/PageNotFound'
import OnePager from './components/OnePager';

export default () => (
  <Router history={createBrowserHistory()}>
    <Switch>
          <Route exact path="/product/:id?" component={Product} />
          <Route exact path="/item/:id?" component={Item} />
          <Route exact path="/company/:id?" component={Company} />
          <Route exact path="/activity/" component={Activity} />
          <Route exact path="/:token?" component={OnePager} />
          <Route exact path="/" component={Scan}/>
          <Route exact path="/s/" component={Scan}/>
          <Route exact path="/s/:token?" component={OnePager} />
          <Route component={PageNotFound} />
    </Switch>
  </Router>
)
