export default {
   //A
   ACTIVITY_NAME: '事件名称',
   ACTIVITY_INFO: '事件信息',
   //C
   CODE_BY: '生产方',
   CREATED_ON: '创建于',
   COMPANY_INFO: '公司信息',
   //D
   DELIVERY_HISTORY: '物流历史',
   //E
   ERR_LOAD: '加载失败，错误原因：',
   //L
   LOAD: '装载',
   //P
   PAGE_NOT_FOUND: '页面不存在！',
   PRODUCT_DESCRIPTION: '产品描述',
   PRODUCT_INFO: '产品信息',
   //T
   TRANSFER: '运输',
   //W
   WEBSITE: '网站',
}