import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeLocale } from '../../actions/index'

import { FormattedMessage } from 'react-intl';
import { IntlProvider } from 'react-intl'
import { TEXT } from '../../constant/text'
import { COLOR } from '../../config/theme'
import PowerBy from '../../components/PowerBy'
import reportIcon from '../../img/view-report-icon.png'
import Breadcrumbs from '../../components/Breadcrumbs'
class Activity extends Component {

   isBase64Image = (str) => {
      const base64Matcher = new RegExp('^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})([=]{1,2})?$');
      const preFix = 'data:image/png;base64,'
      if (str.slice(0, 22) !== preFix) return false
      else if (!base64Matcher.test(str.slice(22))) return false
      return true
   }

   isFileURL = (str) => {
      const preFix = 'https://'
      if (str.slice(0, 8) !== preFix) return false
      return true
   }

   renderAttributes(item, i) {
      let valueObj
      if (this.isBase64Image(item.value)) {
         valueObj = (
            <div style={{
               paddingTop: 15,
               width: '100%',
               border: 2,
               borderColor: COLOR.GRAY_BUTTON,
               borderStyle: 'solid'}}>
               <img
                  resizeMode={'contain'}
                  style={{
                     width: 250,
                     height: 150,
                  }}
                  src={item.value}
                  alt={'signature'}
               />
            </div>
         )
      } else {
         const isFile = this.isFileURL(item.value)
         valueObj = isFile ? (
            <Button
               variant="contained" size="small"
               href={item.value}
               style={{
                  backgroundColor: COLOR.GREEN_BUTTON, color: 'white',
                  textTransform: 'capitalize',
                  fontFamily: 'OpenSans',
                  fontSize: 16,
                  borderRadius: 0,
                  boxShadow: 'none'
               }}>
               <img style={{width: 15, marginRight: 8}} src={reportIcon} alt={'View Report Icon'}/>
               {`View ${item.key}`}
            </Button>
         ) :
         (
            <Typography
               className="body-text"
            >
               {item.value}
            </Typography>
         )
      }
      return (
         <Grid item key={i} style={{width: '100%'}}>
            <Typography
               className="body-header"
               style={{ marginTop: 15, marginBottom: 5, textTransform: 'capitalize'}}
            >{item.key}</Typography>
            {valueObj}
         </Grid>
      )
   }

   renderActivityInfo = (info) => {
      const infoList = []
      if (!info) return infoList
      Object.keys(info).map((key) => {
         if (typeof info[key] === 'string') infoList.push({ key, value: info[key] })
         else {
            const subObj = info[key]
            if (subObj) {
               Object.keys(subObj).map((subKey) => {
                  if (typeof subObj[subKey] === 'string') {
                     infoList.push({ key: subKey, value: subObj[subKey] })
                  }
                  return infoList
               })
            }
         }
         return infoList
      })
      return infoList.map((info, i) => this.renderAttributes(info, i))
   }

   render() {
      const param = this.props.location.state
      const width = 0.95 * param.width
      const locale = this.props.theme.locale
      const messageIntl = this.props.theme.messageIntl
      return (
         <IntlProvider locale={locale} messages={messageIntl}>
         <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
         >
            <Breadcrumbs
               href={`/${param.token}`}
               name={<FormattedMessage id={TEXT.PRODUCT_INFO} defaultMessage={'Product Info'} />}
               curName={<FormattedMessage id={TEXT.ACTIVITY_INFO} defaultMessage={'Activity Info'} />}
            />
            <Grid
               container
               direction="column"
               justify="flex-start"
               style={{
                  width,
                  minWidth: 300,
                  maxWidth: 400,
                  marginTop: 10
               }}
            >
            <Grid
               container
               direction="column"
               justify="flex-start"
               alignItems="flex-start"
               style={{
                  backgroundColor: '#F5F3F5',
                  width: '100%',              
                  minHeight: 120,
                  position: 'relative',
                  paddingTop: 12,
                  paddingLeft: 12
                     }}>
               <Typography className="subTitle" style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id={TEXT.ACTIVITY_NAME} defaultMessage={TEXT.ACTIVITY_NAME} />
               </Typography>
               <Typography className="openSans" style={{ fontSize: 26, marginBottom: 30 }} >
                  {param.activityName}
               </Typography>
               <Typography
                  className="caption"
                  style={{
                     position: 'absolute', right: 10, bottom: 10,
                     color: COLOR.GRAY_CAPTION
                  }}>
                  <FormattedMessage id={TEXT.CREATED_ON} defaultMessage={'Created on'} />
                  {' ' + moment(param.createdAt).format('ll') + ' ' + moment(param.createdAt).format('LT')}
               </Typography>
            </Grid>
            <Grid
               container
               direction="column"
               justify="flex-start"
               alignItems="flex-start"
               style={{ width: '100%' }}
               >
               {param.location && this.renderAttributes({ key: 'Location', value: param.location})}
               {param.info && this.renderActivityInfo(param.info)}
            </Grid>
            </Grid>
         <PowerBy style={{ marginTop: 20 }}/>
         </Grid>
         </IntlProvider>
      );
   }
}

const mapStateToProps = state => {
   return {
      theme: state.theme
   }
}

Activity.propTypes = {
   location: PropTypes.object.isRequired
}

export default connect(mapStateToProps, dispatch => bindActionCreators({ changeLocale }, dispatch))(Activity)

