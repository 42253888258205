// Import the React and ReactDOM libraries
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Routes from './routes';
import { IntlProvider, addLocaleData } from 'react-intl';
import zh from 'react-intl/locale-data/zh';
import en from 'react-intl/locale-data/en';
import zh_CN from './constant/intl/zh';
import en_GB from './constant/intl/en';
import { store } from './utils/configureStore'
import './index.css';
import './fonts/OpenSans.ttf'
import './fonts/OpenSans-SemiBold.ttf'
import './fonts/OpenSans-Bold.ttf'

addLocaleData([...en, ...zh])
//Create a react component
const App = () => {
    let l = navigator.language;
    let messages = 'en';
    if (l.split('-')[0] === 'en') {
        messages = en_GB;
    } else {
        messages = zh_CN;
    }
    return (
        <IntlProvider locale={l} messages={messages}>
            <Provider store={store}>
                <Routes />
            </Provider>
        </IntlProvider>
    )
}

// Take the react component and show it on the screeen
ReactDOM.render(
    <App />,
    document.querySelector('#root')  // public/index.html
);