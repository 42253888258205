import React, { Component } from 'react'
//material UI import
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { IntlProvider } from 'react-intl'
import { injectIntl } from 'react-intl'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { scanItem, findOneItemMongo, getPackage, changeLocale } from '../../actions/index'

import { MEDIATYPE, TEXT, ACTIVITY } from '../../constant/text'
import { httpHandler } from '../../utils/httpHandler'
import Error from '../../components/Error'
import Media from '../../components/Media'
import ItemPackInfo from '../../components/ItemPackInfo'
import PowerBy from '../../components/PowerBy'

const httpHandlerFunc = httpHandler
class ScanResult extends Component {
  constructor(props) {
    super(props)
    this.widthMax = 640
    this.state = {
      isLoading: true,
      oneItemInfo: null,
      onePackInfo: null,
      error: null,
      width: 0,
      height: 0,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.props.scanItem(this.props.match.params.token).then(res => {
      const result = httpHandlerFunc(res)
      if (result.mes === 'SUCCESS') {
        const isItem = result.data.type === 'item'
        if (isItem) {
          this.props.findOneItemMongo(result.data.data.id).then(res => {
            const result = httpHandlerFunc(res)
            const oneItemInfo = result.data.data
            this.setState({
              oneItemInfo,
              isLoading: false
            })
          }).catch((err) => {
            const errMsg = err.response && err.response.data ? err.response.data.message : TEXT.ERR_UNEXPECTED
            this.setState({ error: errMsg, isLoading: false })
          })
        } else {
          // get package by id
          this.props.getPackage(result.data.data.id).then(res => {
            const result = httpHandlerFunc(res)
            const onePackInfo = result.data.data
            this.setState({
              onePackInfo,
              isLoading: false
            })
          }).catch((err) => {
            const errMsg = err.response && err.response.data ? err.response.data.message : TEXT.ERR_UNEXPECTED
            this.setState({ error: errMsg, isLoading: false })
          })
        }
      }
      if (result.mes === 'FAILURE') {
        this.setState({ error: result.failedText, isLoading: false })
      }
    }).catch((err) => {
      const errMsg = err.response && err.response.data ? err.response.data.message : TEXT.ERR_UNEXPECTED
      this.setState({ error: errMsg, isLoading: false })
    })
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  generateHistoryList = (transactions) => {
    const historyList = []
    transactions.map((tran) => {
      const status = tran.status
      let action = ''
      switch (status) {
        case 'received':
          if (tran.signeeCompany.type === 'logistics') action = TEXT.DELIVERED_BY
          else if (tran.signeeCompany.type === 'retailer') action = TEXT.RECEIVED_BY
          tran.companyAction = action
          historyList.push(tran)
          break
        case 'sold':
          action = TEXT.SOLD_BY
          tran.companyAction = action
          historyList.push(tran)
          break
        default:
          break
      }
      return historyList
    })
    return historyList
  }

  generateActivityList = (activities) => {
    const list = []
    if (!activities) return list
    activities.map((each) => {
      const tempActivity = each
      let action = ''
      switch (each.activity) {
        case ACTIVITY.TRANSFER:
          action = TEXT[ACTIVITY.TRANSFER]
          break
        case ACTIVITY.LOAD:
          action = TEXT[ACTIVITY.LOAD]
          break
        default:
          action = `${each.activity} `
          break
      }
      tempActivity.companyAction = action
      return list.push(tempActivity)
    })
    return list
  }

  getInfo = () => {
    const oneItemInfo = this.state.oneItemInfo
    const onePackInfo = this.state.onePackInfo
    const info = {}
    if (oneItemInfo) {
      const activities = Array.isArray(oneItemInfo.activities) ? oneItemInfo.activities : []
      const mediaType = oneItemInfo.product.mediaType
      info.name = oneItemInfo.name
      if (oneItemInfo.ownedCompany.address && oneItemInfo.ownedCompany.address.country) {
        info.address = oneItemInfo.ownedCompany.address.country
      }
      info.description = oneItemInfo.product.description
      info.attributes = oneItemInfo.product.attributes || []
      info.history = this.generateActivityList(activities)
      info.oneInfo = oneItemInfo

      info.type = mediaType === 'videoCover' ?
        MEDIATYPE.VIDEO : MEDIATYPE.IMG
      info.src = info.type === MEDIATYPE.VIDEO ?
        oneItemInfo.product.videoCover : oneItemInfo.product.imageProduct
      return info
    } else if (onePackInfo) {
      const activities = Array.isArray(onePackInfo.activities) ? onePackInfo.activities : []
      info.name = onePackInfo.packageType.name
      info.description = onePackInfo.packageType.description
      info.attributes = []
      info.history = this.generateActivityList(activities)
      info.oneInfo = onePackInfo

      info.type = 'IMG'
      info.src = onePackInfo.packageType.image
      return info
    } else return undefined
  }
  
  render() {
    const info = this.getInfo()
    const locale = this.props.theme.locale
    const messageIntl = this.props.theme.messageIntl
    if (this.state.isLoading) return <CircularProgress />
    else if (this.state.error) return (
      <IntlProvider locale={locale} messages={messageIntl}>
        <Error error={this.state.error} />
      </IntlProvider>
    )
    else if (info) return (
      <IntlProvider locale={locale} messages={messageIntl}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          spacing={16}
      >
        <Grid container justify="flex-end"> 
          <Button
              className="button-text"
              style={locale === 'en' ?
                { color: '#3fae2a'} :
                { color: 'grey' }
              }
            onClick={() => {this.props.changeLocale('en')}}
          >EN</Button>
            <div style={{
              width: 1, height: 25, backgroundColor: 'grey',
              marginTop: 5
            }}></div>
          <Button
            className="button-text"
              style={locale === 'zh' ?
                { color: '#3fae2a' } :
                { color: 'grey' }}
          onClick={() => {this.props.changeLocale('zh')}}
          >中文</Button>
        </Grid>
          <Media
            widthMax={this.widthMax}
            width={this.state.width}
            type={info.type}
            src={info.src}
          />
          <ItemPackInfo
            widthMax={this.widthMax}
            width={this.state.width}
            name={info.name}
            address={info.address}
            description={info.description}
            attributes={info.attributes}
            history={info.history}
            oneInfo={info.oneInfo}
            token={this.props.match.params.token}
          />
          <PowerBy />
        </Grid>
      </IntlProvider>
    )
    else return <CircularProgress />
  }
}

const mapStateToProps = state => {
  return {
    itemInfo: state.oneItemInfo,
    theme: state.theme
  }
}

export default injectIntl(
  connect(mapStateToProps, dispatch => bindActionCreators({ scanItem, findOneItemMongo, getPackage, changeLocale }, dispatch))(ScanResult)
)