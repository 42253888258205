import axios from 'axios'
import API from '../config/api'
import { GET_ONE_ITEM, GET_ONE_ITEM_MONGO, GET_PACKAGE } from './'

export function findOneItemMongo(id) {
  const url = `${API.GET.findOneItem}/${id}`
  const config = {
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  const request = axios.request(config)
  return {
    type: GET_ONE_ITEM_MONGO,
    payload: request,
  }
}

export function findOneItem(id) {
  const url = `${API.GET.findOneItem}/${id}/blockchain`
  const config = {
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  const request = axios.request(config)
  return {
    type: GET_ONE_ITEM,
    payload: request,
  }
}

export function getPackage(id) {
  const url = `${API.GET.getPackage}${id}`;
  const config = {
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  };

  const request = axios.request(config);
  return {
    type: GET_PACKAGE,
    payload: request,
  };
}
