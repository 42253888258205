import Immutable from 'immutable';

const initialState = Immutable.Map({
  //item
  itemDetail: {
    item: {
      id: '',
      name: '',
      ownedCompany: '',
      product: {
        id: '',
        imageProduct: '',
        recall: [],
        attributes: []
      },
      status: '',
    },
    type: '',
    updatedAt: '',
  },
  //package
  packageDetail: {
    package: {
      id: '',
      packageType: {
        description: '',
        image: '',
        name: '',
      },
      signeeCompany: '',
      status: '',
    },
    type: '',
    updatedAt: '',
  },
  //list
  itemList: [],
  itemListWithoutLogin: [],
});

const actionsMap = {
  SCAN_ITEM(state, action) {
    try {
      const status = action.payload ? action.payload.status : null;
      if (status === 200 || (action.payload.response !== undefined && action.payload.response.data !== undefined && action.payload.response.data.code !== undefined && action.payload.response.data.code === 'E_TOKEN_STATUS_SOLD')) {
        let data;
        // let ownedCompany;
        if (status === 200) {
          data = action.payload.data;
          // ownedCompany = data.data.ownedCompany
        } else {
          data = action.payload.response.data.data;
        }
        if (data.type === 'package') {
          return state.set('packageDetail', {
            package: {
              id: data.data.id,
              packageType: {
                description: data.data.packageType.description,
                image: data.data.packageType.image,
                name: data.data.packageType.name,
              },
              signeeCompany: data.data.signeeCompany,
              status: data.data.status,
            },
            type: data.type,
            updatedAt: data.updatedAt,
          });
        } else if (data.type === 'item') {
          return state.set('itemDetail', {
            item: {
              id: data.data.id,
              name: data.data.name,
              ownedCompany: data.data.ownedCompany,
              product: {
                id: data.data.product.id,
                imageProduct: data.data.product.imageProduct,
                recall: data.data.product.recall,
                attributes: data.data.product.attributes
              },
              status: data.data.status,
            },
            type: data.type,
            updatedAt: data.updatedAt,
          });
        }
      }
    } catch (error) {
      console.log(error)
    }
    return state;
  },

}

export default (state = initialState, action) => {
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) return state;
  return reduceFn(state, action);
};

