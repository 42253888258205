export default {
   //A
   ACTIVITY_NAME: 'Activity Name',
   ACTIVITY_INFO: 'Activity Info',
   //C
   CODE_BY: 'Code generated by',
   CREATED_ON: 'Created on',
   COMPANY_INFO: 'Company Info',
   //D
   DELIVERY_HISTORY: 'Delivery History',
   //E
   ERR_LOAD: 'Failed to load because of the error: ',
   //L
   LOAD: 'Load',
   //P
   PAGE_NOT_FOUND: 'Page Not Found!',
   PRODUCT_DESCRIPTION: 'Product Description',
   PRODUCT_INFO: 'Product Info',
   //T
   TRANSFER: 'Transfer',
   //W
   WEBSITE: 'Website',
}