/* eslint global-require: 0 */

import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, autoRehydrate } from 'redux-persist';
// import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import reducer from './reducers';
import Raven from "raven-js";
import createRavenMiddleware from "raven-for-redux";

const prod = process.env.NODE_ENV === 'production'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let middleware = {}

if (prod) {
  Raven.config(`https://${process.env.REACT_APP_SENTRY_KEY}@sentry.io/${process.env.REACT_APP_SENTRY_PROJECT}`).install()
  middleware = applyMiddleware(
    thunk,
    promise,
    createRavenMiddleware(Raven, {})
  )
} else {
  middleware = applyMiddleware(
    thunk,
    promise
  )
}

const enhancer = composeEnhancers(
  middleware,
  //persist purpose
  autoRehydrate()
)

export default function configureStore(initialState) {
  const store = createStore(reducer, initialState, enhancer);
  //persist purpose
  const config = {
    whitelist: ['userProfile']
  }
  persistStore(store, config);

  if (module.hot) {
    module.hot.accept(() => {
      store.replaceReducer(require('./reducers').default);
    });
  }
  return store;
}
