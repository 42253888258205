// import { httpHandler } from '../utils/httpHandler'
// const httpHandlerFunc = httpHandler
const initialState = {
  productinfo: {
    name: 'product',
    type: 'large',
    description: 'good product',
  },
  allproducts: [],
}

const actionsMap = {
  FIND_PRODUCT_ACTION(state, action) {
    const payload = action.payload ? action.payload : null
    if (payload.status === 200) {
      localStorage.setItem('oneProductName', payload.data.data.name)
      return {
        ...state,
        oneProductInfo: payload.data.data,
      }
    }
    return state
  },
}

export default (state = initialState, action) => {
  const reduceFn = actionsMap[action.type]
  if (!reduceFn) {
    return state
  }
  return reduceFn(state, action)
}
