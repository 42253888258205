const themes = {
  COLOR: {
    BLACK: '#373935',
    DARK_GRAY: '#212121',
    GREEN: '#3baf29',
    GREEN_BUTTON: '#3FAE2A',
    BLUE_BG: '#1c252c',
    WHITE: '#fff',
    WHITE_LINK: '#ceced0',
    GRAY: '#cccccc',
    GRAY_ICON: '#b3b3b3',
    LIGHT_GREEN: '#dcefd5',
    RED_WARNING: '#c1272d',
    MASK_COLOR: '#0000004D',
    GRAY_OPACITY: 'rgba(211, 211, 211, 0.3)',
    BLACKMORES_BLUE: '#009EA0',
    GRAY_BUTTON: '#F5F3F5',
    GRAY_BORDER: '#DDD',
    GRAY_LOGO: '#9A9B9D',
    GRAY_BAR: '#E1E1E1',
    GRAY_CAPTION: '#4A4A4A'
  }
}

module.exports = themes;