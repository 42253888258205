import React, { Component } from "react";
import { PropTypes } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import YouTube from 'react-youtube'
import getYouTubeId from 'get-youtube-id'
import defaultProduct from '../../img/product.png'
import { MEDIATYPE } from '../../constant/text'
import { Player, BigPlayButton } from 'video-react';
import { DEFAULTVIDEO } from '../../constant/text'

export default class Media extends Component {

   constructor(props) {
      super(props)
      this.mediaContainerStyle = {
         maxHeight: 0.6 * this.props.widthMax,
         maxWidth: this.props.widthMax,
         width: this.props.width,
         height: 0.6 * this.props.width,
      }
   }

   getVideo = () => {
      const isYoutube = getYouTubeId(this.props.src)
      const opts = {
         playerVars: {
            autoplay: 0,
         },
      }
      if (isYoutube) return (
         <YouTube videoId={this.props.src} opts={opts} onReady={this._onReady} />
      )
      else return (
            <Player
               autoPlay
               src={this.props.src || DEFAULTVIDEO}
            >
               <BigPlayButton position="center" />
            </Player>
      )
   }

   getImage = () => {
      return (
         <img
            style={this.mediaContainerStyle}
            src={this.props.src || defaultProduct}
            alt={MEDIATYPE.IMG} />
      )
   }
   
   render() {
      return (
         <Grid item>
            {this.props.type === MEDIATYPE.VIDEO ?
               <div style={this.mediaContainerStyle}>
                  {this.getVideo()}
               </div>
               :
               this.getImage()
            }
         </Grid>
      )
   }
}

Media.propTypes = {
   widthMax: PropTypes.number.isRequired,
   width: PropTypes.number.isRequired,
   type: PropTypes.string.isRequired,
   src: PropTypes.string.isRequired,
}