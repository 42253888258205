import React, { Component } from "react";
import { PropTypes } from 'prop-types';
import moment from 'moment';
import 'moment/locale/zh-cn.js';
import 'moment/locale/en-au.js';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl'
import { TEXT } from '../../constant/text'
import { COLOR } from '../../config/theme'
import acceptImg from '../../img/accept.png'
import releaseImg from '../../img/release.png'

export default class ItemPackInfo extends Component {

   productAttributes = (object, key) => {
      return (
         <Grid item key={object.key} style={{ marginTop: 10 }}>
            <Typography className="body-header">
               {object.key}
            </Typography>
            <Typography className="body-text">
               {object.value}
            </Typography>
         </Grid>
      )
   }

   deliveryChain = () => {
      const oneInfo = this.props.oneInfo
      const historyList = this.props.history
      return (
         <Grid item>
            {this.deliveryChainItem({
               key: 0,
               createdAt: oneInfo.createdAt,
               first: true,
               last: !historyList.length,
               companyId: oneInfo.ownedCompany.id,
               companyName: oneInfo.ownedCompany.name,
               companyAction: TEXT.CODE_BY,
               location: this.combineAddress(
                  oneInfo.ownedCompany.address.suburb,
                  oneInfo.ownedCompany.address.state,
                  oneInfo.ownedCompany.address.country),
               href: `/company/${oneInfo.ownedCompany.id}`,
               info: null
            })}
            {historyList.map((history, i) => {
               return this.deliveryChainItem({
                  key: history.id,
                  createdAt: history.createdAt,
                  last: (+i + 1) === historyList.length,
                  companyId: history.signeeCompany.id,
                  companyName: history.signeeCompany.name,
                  companyAction: history.companyAction,
                  location: this.combineAddress(
                     history.signeeCompany.address.suburb,
                     history.signeeCompany.address.state,
                     history.signeeCompany.address.country),
                  href: `/activity`,
                  info: history.info
               })
            })}
         </Grid>
      )
   }

   combineAddress = (...args) => args.reduce((prev, cur) => {
      if (prev !== '' && prev !== undefined) { return prev.concat(', ').concat(cur) }
      return cur
   })

   deliveryChainItem = (item) => {
      const first = item.first
      const last = item.last
      return (
         <Grid item key={item.key}>
            <Grid container justify="space-evenly" alignItems="center" style={{ height: 90 }}>
               <Grid item>
                  <Typography className="body-text">
                     {moment(item.createdAt).format('ll')}
                  </Typography>
                  <Typography className="body-text"
                     style={{ color: '#B5B5B5', textAlign: 'right'}}>
                     {moment(item.createdAt).format('LT')}
                  </Typography>
               </Grid>
               <Grid item style={{ width: 17 }}>
                  <div style={{
                     width: 3, height: 31, position: 'relative', left: 6,
                     backgroundColor: first ? COLOR.WHITE : COLOR.GREEN
                  }} />
                  <img style={{ width: 15, height: 28, position: 'relative' }}
                     src={first ? releaseImg : acceptImg}
                     alt={first ? 'release' : 'accept'} />
                  <div style={{
                     width: 3, height: 32, position: 'relative', top: -4, left: 6,
                     backgroundColor: last ? COLOR.WHITE : COLOR.GREEN
                  }} />

               </Grid>
               <Grid item style={{ width: 0.3 * this.props.width, minWidth: 160 }}>
                  <Link
                     to={{
                        pathname: item.href,
                        state: {
                           activityName: item.companyAction,
                           createdAt: item.createdAt,
                           location: item.location,
                           info: item.info,
                           width: this.props.width,
                           locale: this.props.locale,
                           token: this.props.token
                        }
                     }}
                     style={{ textDecoration: 'none' }}>
                     <Typography className="body-text text-hidden" style={{ maxHeight: 46 }}>
                        <FormattedMessage id={item.companyAction} defaultMessage={item.companyAction} /> {item.companyName}
                     </Typography>
                     <Typography className="caption" noWrap
                        style={{ color: '#B5B5B5' }}>
                        {item.location}
                     </Typography>
                  </Link>
               </Grid>
            </Grid>
            <Divider />
         </Grid>
      )
   }

   render() {
      return (
         <Grid
            item
            style={{ width: this.props.width, maxWidth: this.props.widthMax }}
         >
            <Grid container direction="column" alignItems="flex-start">
               <Typography className="title">
                  {this.props.name}
               </Typography>
               {this.props.address &&
                  <Typography className="subTitle">
                     {this.props.address}
                  </Typography>
               }
            </Grid>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            {this.props.description &&
               <Grid container direction="column" alignItems="flex-start">
               <Typography className="body-header">
                  <FormattedMessage id={TEXT.PRODUCT_DESCRIPTION} defaultMessage={TEXT.PRODUCT_DESCRIPTION} />
                  </Typography>
                  <Typography className="body-text">
                  {this.props.description}
                  </Typography>
               </Grid>
            }
            <Grid container direction="column" alignItems="flex-start">
               {this.props.attributes.map((object, key) => this.productAttributes(object, key))}
            </Grid>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Grid container direction="column">
               <Typography className="title">
                  <FormattedMessage id={TEXT.DELIVERY_HISTORY} defaultMessage={TEXT.DELIVERY_HISTORY} />
               </Typography>
               {this.deliveryChain()}
            </Grid>
         </Grid>

      )
   }
}

ItemPackInfo.propTypes = {
   widthMax: PropTypes.number.isRequired,
   width: PropTypes.number.isRequired,
   name: PropTypes.string.isRequired,
   address: PropTypes.string,
   description: PropTypes.string,
   attributes: PropTypes.array,
   history: PropTypes.array,
   oneInfo: PropTypes.object.isRequired,
   token: PropTypes.string.isRequired,
}