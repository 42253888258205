// import { combineReducers } from 'redux-immutablejs';
import { combineReducers } from 'redux';
// import { reducer as formReducer } from 'redux-form/immutable';
import product from './product';
import allItems from './items';
import scan from './scan';
import company from './company'
import theme from './theme'

const allReducers = combineReducers({
  product,
  allItems,
  scan,
  company,
  theme
  // form: formReducer,
});

export default allReducers;
