import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import logo from '../../img/tbsx3-4.png';
import { TEXT } from '../../constant/text';
import { FormattedMessage } from 'react-intl';


export default class Error extends Component {
   render() {
      return (
         <Grid
            container
            justify="center"
            alignItems="center"
         >
            <img src={logo} alt={'logo'} style={{width: 60, height: 60, margin: 10 }}/>
            <Typography className="body-text" style={{ margin: 10 }}>
               <FormattedMessage id={TEXT.ERR_LOAD} defaultMessage={TEXT.ERR_LOAD} /> {this.props.error}
            </Typography>
         </Grid>
      );
   }
}

Error.propTypes = {
   error: PropTypes.string.isRequired
}