import React, { Component } from 'react'
import { PropTypes } from 'prop-types';
//material UI import
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import { IntlProvider } from 'react-intl'
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getCompany } from '../../actions/index'

import { TEXT } from '../../constant/text'
import Error from '../../components/Error'
import defaultProduct from '../../img/product.png'
import PowerBy from '../../components/PowerBy'
import Breadcrumbs from '../../components/Breadcrumbs'
class CompanyProfile extends Component {
   constructor(props) {
      super(props)
      this.companyId = this.props.match.params.id
      this.widthMax = 640
      this.state = {
         isLoading: true,
         companyProfile: null,
         error: null,
         width: 0,
         height: 0,
      }
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
   }

   componentDidMount() {
      this.props.getCompany(this.companyId)
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
   }

   componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
   }

   updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
   }

   render() {
      const param = this.props.location.state
      const locale = this.props.theme.locale
      const messageIntl = this.props.theme.messageIntl
      if (this.props.isLoading) return <CircularProgress />
      else if (this.props.error) return (
         <IntlProvider locale={locale} messages={messageIntl}>
            <Error error={this.props.error.message} />
         </IntlProvider>
      )
      else return (
         <IntlProvider locale={locale} messages={messageIntl}>
         <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
         >
            <Breadcrumbs
               href={`/${param.token}`}
               name={<FormattedMessage id={TEXT.PRODUCT_INFO} defaultMessage={'Product Info'} />}
               curName={<FormattedMessage id={TEXT.COMPANY_INFO} defaultMessage={'Company Info'} />}
            />
            <Grid item>
               <Grid container>
                  <img
                     style={{
                        width: this.state.width,
                        height: 0.6 * this.state.width,
                        maxWidth: this.widthMax, maxHeight: 0.6 * this.widthMax
                     }} src={this.props.companyInfo.imageProfile || defaultProduct} alt={'product'} />               
               </Grid>
               <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="center"
                  style={{ marginTop: 10, maxWidth: this.widthMax }}>
                  <Typography className="title">
                     {this.props.companyInfo.name}
                  </Typography>
                  <Link href={this.props.companyInfo.URL} underline='none'>
                        <Typography className="subTitle" style={{ textDecoration: 'underline' }}>
                           <FormattedMessage id={TEXT.WEBSITE} defaultMessage={TEXT.WEBSITE} />
                     </Typography>
                  </Link>
               </Grid>
               <Divider style={{ marginTop: 10, marginBottom: 10 }}/>
               <Grid container style={{ maxWidth: this.widthMax, padding: 10 }}>
                  <Typography className="body-text">
                     {this.props.companyInfo.description}
                  </Typography>
               </Grid>
            </Grid>
         <PowerBy style={{ marginTop: 20 }}/>
         </Grid>
         </IntlProvider>
      )
   }
}

const mapStateToProps = state => {
   return {
      companyInfo: state.company.companyInfo,
      isLoading: state.company.isLoading,
      error: state.company.error,
      theme: state.theme
   }
}

CompanyProfile.propTypes = {
   location: PropTypes.object.isRequired
}

export default connect(mapStateToProps, dispatch => bindActionCreators({ getCompany }, dispatch))(CompanyProfile)