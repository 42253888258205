import React, { Component } from 'react'
// import { PropTypes } from 'prop-types'

// import YouTube from 'react-youtube'
// import getYouTubeId from 'get-youtube-id'
// //material UI import
// import Avatar from 'material-ui/Avatar'
// import { FormattedMessage } from 'react-intl'

//react-redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// //product api call
import { productFindOne } from '../../actions/index'

// //component import
// import NoPermission from '../../../components/NoPermision'

// import itemIcon from '../../../img/item-icon.png'
import { httpHandler } from '../../utils/httpHandler'
// import ErrorHandler from '../../../components/ErrorHandler'
const httpHandlerFunc = httpHandler

class ViewProductReport extends Component {
  // static contextTypes = {
  //   router: PropTypes.object,
  // }

  // static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      oneProductInfo: [],
      error: null,
    }
  }

  componentDidMount() {
    this.props.productFindOne(this.props.match.params.id).then(res => {
      const result = httpHandlerFunc(res)
      if (result.mes === 'SUCCESS') {
        console.log('result', result.data)
        const oneProductInfo = result.data
        this.setState({
          pageLoading: false,
          attributes: oneProductInfo.attributes,
          oneProductInfo,
          imageProduct: oneProductInfo.imageProduct,
        })
      }
      if (result.mes === 'FAILURE') {
        this.setState({ error: result, loading: false })
      }
    })
  }

  // renderAttributeLeft = att => {
  //   let content = []
  //   if (att === undefined || att.length === 0) {
  //     return content.push('N/A')
  //   } else {
  //     att.map((att, index) => {
  //       return content.push(
  //         <span className="dashboard-font" key={index}>
  //           {att.key}
  //           <br />
  //         </span>
  //       )
  //     })
  //   }
  //   return content
  // }
  // renderAttributeRight = att => {
  //   let content = []
  //   if (att === undefined || att.length === 0) {
  //     return content.push('N/A')
  //   } else {
  //     att.map((att, index) => {
  //       return content.push(
  //         <span key={index}>
  //           {att.value}
  //           <br />
  //         </span>
  //       )
  //     })
  //   }
  //   return content
  // }

  //main render place
  render() {

    return (
      <div>
        <text>{this.state.oneProductInfo.name}</text>
      </div>
    )
  //   let product = this.state.oneProductInfo
  //   //library to get youtube id
  //   let id = getYouTubeId(product.videoCover)
  //   //videoID = videoID.substring(videoID.indexOf("=") + 1);
  //   //youtube video option
  //   const opts = {
  //     maxHeight: '390',
  //     maxWidth: '640',
  //     playerVars: {
  //       autoplay: 0,
  //     },
  //   }
  //   if (this.state.error) {
  //     return <ErrorHandler error={this.state.error} />
  //   } else if (this.state.oneProductInfo) {
  //     return (
  //       <div style={{ marginTop: '-50px', padding: '50px' }}>
  //         <h1 style={{ fontWeight: 'bold', color: 'black', borderBottom: '1px solid black', padding: '20px' }}>
  //           <FormattedMessage id="PRODUCT_DETAILS" defaultMessage="Product details" />
  //         </h1>
  //         <div>
  //           <div style={{ display: 'flex', paddingTop: '30px' }}>
  //             <div style={{ flex: '0.5', paddingRight: '10px' }}>
  //               <Avatar
  //                 size={150}
  //                 src={product.imageProduct && product.imageProduct !== '' ? product.imageProduct : itemIcon}
  //                 style={{ objectFit: 'scale-down' }}
  //               />
  //             </div>
  //             <div style={{ flex: '1' }}>
  //               <p className="dashboard-font" style={{ color: 'black', fontWeight: 'bold' }}>
  //                 <FormattedMessage id="PRODUCT_NAME" defaultMessage="Product Name" />
  //               </p>
  //               {product.name && product.name !== '' ? product.name : 'N/A'}

  //               <p className="dashboard-font" style={{ color: 'black', fontWeight: 'bold' }}>
  //                 <FormattedMessage id="PRODUCT_TYPE" defaultMessage="Product Type/Categorye" />
  //               </p>
  //               {product.type && product.type !== '' ? product.type : 'N/A'}
  //             </div>
  //             <div style={{ flex: '1' }}>
  //               <p className="dashboard-font" style={{ color: 'black', fontWeight: 'bold' }}>
  //                 <FormattedMessage id="YIELD" defaultMessage="Yield" style={this.wordpadding} />
  //               </p>
  //               {product.yield && product.yield !== '' ? product.yield : 'N/A'}
  //               <p className="dashboard-font" style={{ color: 'black', fontWeight: 'bold' }}>
  //                 <FormattedMessage id="URL" defaultMessage="URL" />
  //               </p>
  //               {product.URL && product.URL !== '' ? product.URL : 'N/A'}
  //             </div>
  //           </div>
  //           <div style={{ paddingBottom: '20px' }}>
  //             <p className="dashboard-font" style={{ color: 'black', fontWeight: 'bold' }}>
  //               <FormattedMessage id="PRODUCT_DESCRIPTION" defaultMessage="Product Description" />
  //             </p>
  //             {product.description}
  //           </div>
  //           <div style={{ textAlign: 'left' }}>
  //             <YouTube videoId={id} opts={opts} onReady={this._onReady} />
  //           </div>
  //           <div>
  //             <p className="dashboard-font" style={{ color: 'black', fontWeight: 'bold' }}>
  //               <FormattedMessage id="PRODUCT_ATTRIBUTE" defaultMessage="Product Attribute" />
  //             </p>
  //             <div style={{ display: 'flex' }}>
  //               <div style={{ flex: 1 }}> {this.renderAttributeLeft(product.attributes)}</div>
  //               <div style={{ flex: 3 }}>{this.renderAttributeRight(product.attributes)}</div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     )
  //   } else {
  //     return (
  //       <div className="myProfile">
  //         <div className="myCard">
  //           <NoPermission />
  //         </div>
  //       </div>
  //     )
  //   }
  // }
}

}

const mapStateToProps = state => {
  return {
    productInfo: state.oneProductInfo,
  }
}

export default connect(mapStateToProps, dispatch => bindActionCreators({ productFindOne }, dispatch))(ViewProductReport)

// export default ViewProductReport;