import {
  productFindOne,
} from './product'
import { findOneItem, findOneItemMongo, getPackage } from './items'
import { scanItem } from './scan'
import { getCompany } from './company'
import { changeLocale } from './theme'

const FIND_PRODUCT_ACTION = 'FIND_PRODUCT_ACTION'
const GET_ONE_ITEM = 'GET_ONE_ITEM'
const GET_ONE_ITEM_MONGO = 'GET_ONE_ITEM_MONGO'
const SCAN_ITEM = 'SCAN_ITEM'
const GET_COMPANY = 'GET_COMPANY'
const GET_PACKAGE = 'GET_PACKAGE'
const CHANGE_LOCALE = 'CHANGE_LOCALE'

export {
  FIND_PRODUCT_ACTION,
  productFindOne,
  GET_ONE_ITEM_MONGO,
  findOneItemMongo,
  GET_ONE_ITEM,
  findOneItem,
  SCAN_ITEM,
  scanItem, 
  GET_COMPANY,
  getCompany,
  GET_PACKAGE,
  getPackage,
  CHANGE_LOCALE,
  changeLocale
}
