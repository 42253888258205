// // Local Environment
// const protocol = process.env.REACT_APP_PROTOCOL ||  'http';
// const domain = process.env.REACT_APP_DOMAIN_NAME || '127.0.0.1';
// const port = process.env.REACT_APP_PORT || '8080';

// For Development Purposes
const protocol = process.env.REACT_APP_PROTOCOL || 'https'
const domain = process.env.REACT_APP_DOMAIN_NAME || 'dutchmill.tbsx3.org'
const port = process.env.REACT_APP_PORT || '443'
const route = 'api'
const version = 'v1'

const baseUrl = `${protocol}://${domain}:${port}/${route}/${version}`


export default {
  GET: {
    productFindOne: `${baseUrl}/products/`,
    findOneItem: `${baseUrl}/products/items`,
    getCompany: `${baseUrl}/companies/`,
    getPackage: `${baseUrl}/packages/`,
  },
  POST: {
    scanItem: `${baseUrl}/scans/`,
  }
}
