import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AgliveLogo from '../../img/PoweredByAglive.png'

export default class PowerBy extends Component {
   render() {
      const style = this.props.style || {}
      return (
         <Grid item>
            <img
               style={{
                  marginTop: style.marginTop || 0,
                  marginBottom: style.marginTop || 20,
                  width: style.width || 138,
               }}
               src={AgliveLogo}
               alt={'AGLIVE-LOGO'}
            />
         </Grid>
      );
   }
}

PowerBy.propTypes = {
   style: PropTypes.object,
}